import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@components/layouts/dialog/dialog.component';

interface IMobileAppDialogData {
  isAndroid: boolean;
  isiOS: boolean;
}

@Component({
  selector: 'kzn-open-mobile-app',
  standalone: true,
  imports: [DialogComponent],
  templateUrl: './open-mobile-app.component.html',
  styleUrl: './open-mobile-app.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OpenMobileAppComponent {
  dialogData: IMobileAppDialogData = {
    isAndroid: false,
    isiOS: false,
  }
  appStoreUrl: string = 'https://apps.apple.com/us/app/google-maps/id585027354';
  playStoreUrl: string = 'https://play.google.com/store/apps/details?id=com.kaizen';

  constructor(
    private dialogRef: MatDialogRef<OpenMobileAppComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IMobileAppDialogData
  ) {
    this.dialogData = data;
  }

  openMobileApp(): void {
    window.open(this.dialogData.isiOS ? this.appStoreUrl : this.playStoreUrl, '_blank');
  }

  continueInBrowser(): void {
    this.dialogRef.close();
  }
}
