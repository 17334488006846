<div class="upload-section-container">
    <div class="drag-drop-area" (drop)="handleDrop($event)" (dragover)="allowDrop($event)">
        <div class="drag-drop-content">
            <mat-icon>file_upload</mat-icon>
            <p>Drag and drop your PDF here or</p>
            <button mat-raised-button class="upload-btn" (click)="fileInput.click()">Upload PDF</button>
            <input #fileInput type="file" hidden (change)="onPdfUpload($event)" />
        </div>
    </div>
    <span class="file-name" *ngIf="pdfFileName">{{ pdfFileName }}</span>
</div>

<div class="table-container">
    <div class="table-header">
        <h3 class="table-heading">Manage User(s)</h3>
        <button mat-raised-button class="add-user-btn" (click)="addUser()">
            <mat-icon>add</mat-icon> Add User
        </button>
    </div>

    <mat-form-field appearance="outline" class="width-100">
        <mat-label>App Token</mat-label>
        <mat-select [formControl]="appToken">
            @for(token of allTokens; track token.AppName; let index = $index) {
            <mat-option [value]="token">
                {{
                token.AppName + ' ' + '(' + token.Token + ')'
                }}
            </mat-option>
            }
        </mat-select>
        <mat-error>{{ Messages.MSG_ERROR_REQUIRED }}</mat-error>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="user-table">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let userForm; let i = index" [formGroup]="userForm">
                <input matInput formControlName="name" placeholder="Enter name" [readonly]="!editableRows[i]"
                    style="font-size: 16px" />
                <!--                <mat-error *ngIf="userForm.get('name')?.invalid">Name is required</mat-error>-->
            </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let userForm; let i = index" [formGroup]="userForm">
                <input matInput formControlName="email" placeholder="Enter email" [readonly]="!editableRows[i]"
                    style="font-size: 16px" />
                <!--                <mat-error *ngIf="userForm.get('email')?.invalid">Valid email is required</mat-error>-->
            </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let userForm; let i = index" [formGroup]="userForm">
                <mat-select formControlName="role">
                    <mat-option value="Signer" [disabled]="!editableRows[i]">Signer</mat-option>
                    <mat-option value="Reviewer" [disabled]="!editableRows[i]">Reviewer</mat-option>
                </mat-select>
                <!--                <mat-error *ngIf="userForm.get('role')?.invalid">Role is required</mat-error>-->
            </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td style="text-align: center" mat-cell *matCellDef="let userForm; let i = index">
                <button mat-icon-button color="primary" (click)="toggleEditSave(i)">
                    <mat-icon>{{ editableRows[i] ? 'save' : 'edit' }}</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="deleteUser(i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="mandatory-text">All fields are mandatory*</div>
</div>