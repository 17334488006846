import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "kzn-dialog",
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: "./dialog.component.html",
  styleUrl: "./dialog.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent {
  @Input({ required: true }) title!: string;
  @Input() isActionDisabled: boolean = false;
  @Input({ required: true }) actionLabel!: string;
  @Input() isActionInProgress: boolean = false;
  @Input() actionColor: string = 'primary';
  @Input() showReset: boolean = false;
  @Input() secondaryActionLabel: string = 'Cancel';
  @ContentChildren("content") content!: QueryList<HTMLElement>;
  @Output() actionFired = new EventEmitter();
  @Output() onReset = new EventEmitter();

  constructor(private _dialogRef: MatDialogRef<DialogComponent>) {
    this._dialogRef.disableClose = true;
  }

  saveData() {
    this.actionFired.emit();
  }

  reset() {
    this.onReset.emit();
  }
}
