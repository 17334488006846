import { NgModule } from '@angular/core';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import {CommonModule, NgClass, NgFor, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSlider, MatSliderModule} from "@angular/material/slider";
import {RouterLink} from "@angular/router";

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatStepperModule,
        MatIcon,
        NgIf,
        NgFor,
        ReactiveFormsModule,
        MatButton,
        MatIcon,
        NgClass,
        FormsModule,
        MatSlider,
        CommonModule,
        MatSliderModule,
        MatStepper,
        RouterLink,
    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatStepperModule,
        MatIcon,
        NgIf,
        NgFor,
        ReactiveFormsModule,
        MatButton,
        MatIcon,
        NgClass,
        FormsModule,
        MatSlider,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSliderModule,
        MatInputModule,
        MatSelectModule,
        MatStepper,
        RouterLink,
    ],
})
export class SharedMaterialModule {}
