<div class="page-container">
    <!-- <kzn-header [sidebarRef]=""></kzn-header> -->
    <div class="content-wrapper">
        <!-- Separate Stepper Container -->
        <div class="stepper-container">
            <div class="custom-stepper">
                <div
                        *ngFor="let step of steps; let i = index"
                        class="step"
                        [ngClass]="{
                      active: currentStep === i,
                      completed: i < currentStep
                    }"
                >
                    <span class="step-label">{{ i + 1 }}. {{ step }}</span>
                </div>
            </div>
        </div>

        <!-- Step Content -->
        <div class="step-content-container" #stepContentContainer>
            <div *ngIf="currentStep === 0">
                <app-pdf-upload-users
                        (pdfUploaded)="handlePdfUploaded($event)"
                        (userUpdated)="handleUserUpdated($event)"
                        (appTokenUpdated)="handleAppTokenUpdated($event)"
                        [users]="users"
                ></app-pdf-upload-users>
                <div class="step-buttons">
                    <button mat-raised-button class="custom-reset-button" (click)="resetStepper()">
                        Reset
                    </button>
                    <button
                            mat-raised-button
                            class="custom-next-button"
                            (click)="nextStep()"
                            [disabled]="!(isPdfUploaded && users.length && appToken)"
                            [class.valid]="(isPdfUploaded && users.length && appToken)"
                    >
                        Next
                    </button>
                </div>
            </div>

            <div *ngIf="currentStep === 1">
                <app-pdf-view-edit
                        [pdfFile]="pdfFile"
                        [users]="users"
                        [appToken]="appToken"
                        (previousStep)="previousStep()"
                        (onSubmitComplete)="handleSubmitComplete()"
                        [topPosition]="topPosition"
                        style="display: flex; gap: 1rem;"
                ></app-pdf-view-edit>
                <div class="step-buttons">
                    <button mat-raised-button (click)="goToPrevious()">Back</button>
                    <button
                            mat-raised-button
                            class="custom-submit-button"
                            (click)="submitData()"
                    >
                        Submit
                    </button>
                </div>
            </div>

            <div *ngIf="currentStep === 2">
                <app-pdf-thank-you></app-pdf-thank-you>
            </div>
        </div>
    </div>
    <kzn-footer></kzn-footer>
</div>
