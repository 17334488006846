import { Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SharedMaterialModule } from "@config/shared-material.module";
import { MatPaginator } from "@angular/material/paginator";
import { ToastService } from "@services/toast.service";
import { Constants } from '@config/constants';
import { HttpService } from '@services/http.service';
import { IAppToken } from '@modals/api.modal';
import { Messages } from '@config/messages';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Subscription, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-pdf-upload-users',
    templateUrl: './pdf-upload-users.component.html',
    styleUrl: './pdf-upload-users.component.scss',
    standalone: true,
    imports: [SharedMaterialModule, MatFormFieldModule, MatSelectModule]
})
export class PdfUploadUsersComponent implements OnDestroy {
    @Input() users: Array<{ name: string; email: string; role: string }> = [];
    @Output() pdfUploaded = new EventEmitter<File>();
    @Output() userUpdated = new EventEmitter<Array<{ name: string; email: string; role: string }>>();
    @Output() appTokenUpdated = new EventEmitter<IAppToken>();

    private _httpService = inject(HttpService);
    private _appTokenSubscription!: Subscription;
    pdfFileName: string | null = null;
    userFormArray: FormArray;
    displayedColumns: string[] = ['name', 'email', 'role', 'action'];
    dataSource: MatTableDataSource<AbstractControl<any>, MatPaginator>;
    editableRows: boolean[] = [];
    isLoadingAppTokens: boolean = false;
    allTokens: Array<IAppToken> = [];
    appToken: FormControl = new FormControl("", Validators.required);
    Messages = Messages;

    constructor(private fb: FormBuilder, private _tostr: ToastService) {
        this.userFormArray = this.fb.array([]);
        this.dataSource = new MatTableDataSource(this.userFormArray.controls);
        this.addUser();
        this.getAllTokens();

        this._appTokenSubscription = this.appToken.valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(() => {
            this.appTokenUpdated.emit(this.appToken.value);
        })
    }

    getAllTokens(): void {
        this.isLoadingAppTokens = true;
        this.allTokens = [];
        this._httpService
            .get(
                this._httpService.createEndpoint(Constants.API_URI_APP_TOKEN_GET_ALL)
            )
            .subscribe(
                (response: any) => {
                    this.isLoadingAppTokens = false;
                    this.allTokens = response.Data;
                },
                (errorResponse) => {
                    this.isLoadingAppTokens = false;
                }
            );
    }

    createUserFormGroup(): FormGroup {
        return this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            role: ['', Validators.required],
        });
    }

    onPdfUpload(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            if (file.type !== 'application/pdf') {
                this._tostr.showWarning('Only PDF files are allowed.');
                return;
            }
            this.pdfFileName = file.name;
            this.pdfUploaded.emit(file);
        }
    }

    handleDrop(event: DragEvent): void {
        event.preventDefault();
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            if (file.type !== 'application/pdf') {
                this._tostr.showWarning('Only PDF files are allowed.');
                return;
            }
            this.pdfFileName = file.name;
            this.pdfUploaded.emit(file);

            const fileURL = URL.createObjectURL(file);
            // window.open(fileURL, '_blank');
        }
    }

    allowDrop(event: DragEvent): void {
        event.preventDefault();
    }

    addUser(): void {
        const newUserFormGroup = this.createUserFormGroup();
        this.userFormArray.push(newUserFormGroup);
        this.editableRows.push(true);
        this.updateTable();
    }

    deleteUser(index: number): void {
        if (this.userFormArray.length === 1) {
            this._tostr.showWarning('You must have at least one user in the table.');
            return;
        }
        this.userFormArray.removeAt(index);
        this.editableRows.splice(index, 1);
        this.updateTable();
    }

    toggleEditSave(index: number): void {
        const userForm = this.userFormArray.at(index) as FormGroup;

        if (this.editableRows[index]) {
            if (userForm.valid) {
                this.editableRows[index] = false;
            } else {
                this._tostr.showWarning('Please fill all required fields correctly.');
                return;
            }
        } else {
            this.editableRows[index] = true;
        }
        this.emitUpdatedUsers();
    }

    updateTable(): void {
        this.dataSource.data = this.userFormArray.controls;
    }

    emitUpdatedUsers(): void {
        this.userUpdated.emit(this.userFormArray.value);
    }

    ngOnDestroy(): void {
        this._appTokenSubscription?.unsubscribe();
    }
}
