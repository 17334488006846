import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { SharedMaterialModule } from "@config/shared-material.module";
import { HeaderComponent } from "@components/layouts/header/header.component";
import { PdfUploadUsersComponent } from "@components/pdf-editor/pdf-upload-users/pdf-upload-users.component";
import { FooterComponent } from "@components/layouts/footer/footer.component";
import { PdfViewEditComponent } from "@components/pdf-editor/pdf-view-edit/pdf-view-edit.component";
import { PdfThankYouComponent } from "@components/pdf-editor/pdf-thank-you/pdf-thank-you.component";
import { IAppToken } from '@modals/api.modal';

@Component({
    selector: 'app-pdf-edit-stepper',
    templateUrl: './pdf-edit-stepper.component.html',
    standalone: true,
    imports: [SharedMaterialModule, HeaderComponent, PdfUploadUsersComponent, FooterComponent, PdfViewEditComponent, PdfThankYouComponent],
    styleUrls: ['./pdf-edit-stepper.component.scss']
})
export class PdfEditStepperComponent implements AfterViewInit {
    currentStep = 0;
    isPdfUploaded = false;
    pdfFile: File | null = null;
    users: Array<{ name: string; email: string; role: string }> = [];
    steps = ['Upload & Assign Users', 'Add Tags', 'Submit'];
    topPosition: number = 0;
    appToken!: IAppToken;
    @ViewChild('stepContentContainer') stepContentContainer!: ElementRef;
    @ViewChild(PdfViewEditComponent) pdfViewEditComponent!: PdfViewEditComponent;

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.stepContentContainer) {
                this.topPosition = this.stepContentContainer.nativeElement.offsetTop;
            }
        });
    }

    handlePdfUploaded(file: File): void {
        this.pdfFile = file;
        this.isPdfUploaded = true;
    }

    handleUserUpdated(updatedUsers: Array<{ name: string; email: string; role: string }>) {
        this.users = updatedUsers;
    }

    handleAppTokenUpdated(updatedAppToken: IAppToken) {
        this.appToken = updatedAppToken;
    }

    resetStepper(): void {
        location.reload()
    }

    nextStep(): void {
        if (this.currentStep < this.steps.length - 1) {
            this.currentStep++;
        }
    }

    previousStep(): void {
        if (this.currentStep > 0) {
            this.currentStep--;
        }
    }

    handleSubmitComplete(): void {
        this.currentStep = 2;
    }

    // Trigger the child's submit method
    submitData(): void {
        if (this.pdfViewEditComponent) {
            this.pdfViewEditComponent.submit();
        }
    }

    // Trigger the child's goToPrevious method
    goToPrevious(): void {
        if (this.pdfViewEditComponent) {
            this.pdfViewEditComponent.goToPrevious();
        }
    }
}
