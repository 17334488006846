<div class="toolbox">
    <span class="box-title">Tools</span>
    <!-- <hr /> -->
    <!-- Group 1 -->
    <div class="tool-group">
        <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'esign' }"
            (click)="toggleTool('esign')">
            <mat-icon>edit</mat-icon> Signature
        </button>
        <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'initials' }"
            (click)="toggleTool('initials')">
            <mat-icon>short_text</mat-icon> Initials
        </button>
        <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'stamps' }"
            (click)="toggleTool('stamps')">
            <mat-icon>check_circle</mat-icon> Stamps
        </button>
    </div>
    <!-- <hr /> -->

    <!-- Group 2 -->
    <div class="tool-group">
        <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'text' }"
            (click)="toggleTool('text')">
            <mat-icon>text_fields</mat-icon> Text
        </button>
        <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'date' }"
            (click)="toggleTool('date')">
            <mat-icon>calendar_today</mat-icon> Date
        </button>
        <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'checkbox' }"
            (click)="toggleTool('checkbox')">
            <mat-icon>check_box</mat-icon> Checkbox
        </button>
    </div>
    <!-- <hr /> -->

    <!-- Group 3 -->
    <div class="tool-group">
        <div class="tool-group">
            <button mat-raised-button class="tool-button" [ngClass]="{ 'selected-tool': selectedTool === 'comment' }"
                (click)="toggleTool('comment')">
                <mat-icon>comment</mat-icon> Comment
            </button>
        </div>
    </div>
</div>

<div class="pdf-container" #pdfContainer (click)="handlePdfClick($event)"></div>

<div class="property-box" (click)="$event.stopPropagation()"
    [ngStyle]="{ visibility: selectedAnnotation ? 'visible' : 'hidden' }">
    <span class="box-title property-box__title">Properties</span>
    @if(selectedAnnotation) {
    <!-- Placeholder -->
    <div class="property">
        <!-- <label for="placeholder">Placeholder:</label> -->
        <input id="placeholder" type="text" class="property-input" #placeholderElement
            [(ngModel)]="selectedAnnotation.properties.placeholder"
            (input)="updateAnnotationProperty('placeholder', placeholderElement.value)" />
    </div>

    <!-- Font Size -->
    <div class="property">
        <!-- <label for="fontSize">Font Size:</label> -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Font Size</mat-label>
            <mat-select [(value)]="selectedAnnotation.properties.fontSize"
                (selectionChange)="updateAnnotationProperty('fontSize', $event.value)">
                <mat-option *ngFor="let size of fontSizes" [value]="size">
                    {{ size }} px
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Assign to User -->
    <div class="property">
        <!-- <label for="assignUser">Assign to User:</label> -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Assign to</mat-label>
            <mat-select [(value)]="selectedAnnotation.properties.user"
                (selectionChange)="updateAnnotationProperty('user', $event.value)">
                <mat-option *ngFor="let user of users" [value]="user.email">
                    {{ user.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Copy to Pages -->
    <div class="property">
        <!-- <label for="copyToPages">Copy to Pages:</label> -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
            <mat-label>Copy to</mat-label>
            <mat-select (selectionChange)="copyAnnotationToPages($event.value)">
                <mat-option value="all">All Pages</mat-option>
                <mat-option value="even">Even Pages</mat-option>
                <mat-option value="odd">Odd Pages</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    }
</div>

<!--<div class="pdf-view-below-buttons">-->
<!--    <button mat-raised-button (click)="goToPrevious()">Back</button>-->
<!--    <button mat-raised-button class="custom-submit-button" (click)="submit()" [disabled]="isLoading">Submit</button>-->
<!--</div>-->

<kzn-loader *ngIf="isLoading" [message]="'Submitting data...'" [isOverlay]="true"></kzn-loader>