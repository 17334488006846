import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideToastr } from "ngx-toastr";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { tokenInterceptor } from "@interceptors/token.interceptor";
import { MatNativeDateModule } from "@angular/material/core";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideToastr(),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    importProvidersFrom(MatNativeDateModule), provideAnimationsAsync(), provideAnimationsAsync(),
  ],
};
